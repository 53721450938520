import { values } from 'lodash'

import {
  DATASET,
  ROUTER_DATASET,
  WIDGET_DATASET,
} from '@wix/wix-data-client-common/dist/esm/datasetTypes'
import { DETAILS_DATASET_ROLE } from '@wix/wix-data-client-common-standalone'
import * as modes from '@wix/wix-data-client-common-standalone'
import { hasDynamicFilter } from '../filter-resolvers'
import { PRIMARY, REGULAR, UNCONFIGURED } from '../data/sequenceType'
import { appContext } from '../viewer-app-module/DataBindingAppContext'
import { AppError } from '../logger'
import { datasetIsControlledByDataSource } from '../helpers/datasetUtils'
import datasetEntity from '../dataset/dataset-entity'

const { WRITE } = modes

const getDatasetSequenceType = ({
  collectionId,
  datasetHasDynamicFilter,
  datasetIsDeferred,
  datasetIsRouter,
  datasetIsWriteOnly,
  dataset,
}) => {
  if (!collectionId) {
    return UNCONFIGURED
  }
  return !datasetHasDynamicFilter &&
    !datasetIsDeferred &&
    !datasetIsRouter &&
    !datasetIsWriteOnly &&
    !datasetIsControlledByDataSource(dataset)
    ? PRIMARY
    : REGULAR
}

const getStaticDatasetConfig = (
  obsoleteDataset,
  datasetType,
  connections,
  // new contract
  dataset,
) => {
  const {
    readWriteType,
    filter,
    collectionName: collectionId,
  } = obsoleteDataset
  const datasetIsWriteOnly = readWriteType === WRITE
  const datasetIsMaster = connections.some(
    ({ role }) => role === DETAILS_DATASET_ROLE,
  )
  const datasetIsRouter = datasetType === ROUTER_DATASET
  const datasetIsDeferred =
    datasetEntity.isDeferred(dataset) &&
    !(datasetIsMaster || datasetIsRouter || datasetIsWriteOnly)

  const datasetHasDynamicFilter = filter && hasDynamicFilter(filter)

  const sequenceType = getDatasetSequenceType({
    collectionId,
    datasetHasDynamicFilter,
    datasetIsDeferred,
    datasetIsRouter,
    datasetIsWriteOnly,
    dataset,
  })

  //TODO: migrate these calculations in redux store, or get rid of redux for dataset settings
  return {
    sequenceType,
    datasetIsWriteOnly,
    datasetIsMaster,
    datasetIsRouter,
    datasetIsDeferred,
    datasetHasDynamicFilter,
  }
}

const controllerTypeByDatasetType = {
  regular: DATASET,
  router: ROUTER_DATASET,
  widget: WIDGET_DATASET,
}

const completeControllerConfigs = datasets => {
  //TODO: Split completeControllerConfig. Use new format for dataFetcher instead
  const datasetTypes = values({
    DATASET,
    ROUTER_DATASET,
    WIDGET_DATASET,
  })

  return datasets.map(dataset => {
    const {
      id,
      type,
      collectionId,
      readWriteType,
      deferred,
      private: isPrivate,
      routerConfig,
      pagination: { pageSize, type: paginationType },
      rendering: {
        connections,
        nestedFieldIds: nested,
        componentIdsToRender: updatedCompIds,
      },
      dataLoading: {
        filter,
        sort,
        dataIsInvalidated,
        referenceFieldIdsToFetch: includes,
      },
    } = dataset.config
    const datasetType = controllerTypeByDatasetType[type]

    if (!datasetTypes.includes(datasetType)) {
      appContext.logger.log(
        new AppError(
          `type of controller MUST be one of ${datasetTypes} but is ${datasetType}`,
        ),
      )
    }

    const obsoleteDataset = {
      collectionName: collectionId,
      filter,
      sort,
      includes,
      nested,
      pageSize,
      readWriteType: readWriteTypeMap[readWriteType],
      cursor: paginationType === 'cursor',
      deferred,
      private: isPrivate,
      uniqueFieldValues: [],
    }

    return {
      id,
      compId: id,
      type: datasetType,
      livePreviewOptions: {
        shouldFetchData: dataIsInvalidated,
        compsIdsToReset: updatedCompIds,
      },
      connections,
      dynamicPageData: routerConfig
        ? {
            dynamicUrl: routerConfig.dynamicUrl,
            userDefinedFilter: routerConfig.userDefinedFilter,
            seoV2: routerConfig.seoEnabled,
          }
        : undefined,
      config: {
        dataset: obsoleteDataset,
        datasetStaticConfig: getStaticDatasetConfig(
          obsoleteDataset,
          datasetType,
          connections,
          // new contract
          dataset,
        ),
        // new interface
        ...dataset.config,
      },
      // new interface
      data: dataset.data,
    }
  })
}

const readWriteTypeMap = {
  read: 'READ',
  write: 'WRITE',
  'read-write': 'READ_WRITE',
}

export default completeControllerConfigs
