import { sdk as imageKitSDK } from '@wix/image-kit'
import { prefix as tpaPrefix } from '@wix/data-binding-tpa-actions'
import type { Action } from '@wix/data-binding-tpa-actions'
import type { RecordStoreRecord } from '../record-store/service'
import type { DynamicItemPageSeo } from '../seo/types'
import {
  ROUTER_DATASET,
  DATASET,
  WIDGET_DATASET,
} from '@wix/wix-data-client-common'
import type datasetEntity from '../dataset/dataset-entity'
import {
  GRID_ROLE,
  type DatasetSort,
} from '@wix/wix-data-client-common-standalone'
import type { Connection } from '../types'
import type { FilterTree, Sort } from '../contract'
import type { Features } from './Features'
import type {
  IAppData,
  IPlatformAPI,
  IPlatformServices,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types'
import type { Platform as PlatformInterface } from './contract'
interface PlatformUtilsOverride extends IPlatformAPI {
  // TODO: fix in @wix/native-components-infra
  mediaItemUtils: any
  links: any
}

export interface AppData {
  gridAppId: string
  veloCodeIsPresentOnCurrentPage: boolean
  blocksAppIsPresentOnSite: boolean
}

type Bi = any

type TpaActionImplementation = (params: {
  currentItem: RecordStoreRecord
  wixSdk: any
}) => void

export interface IWixAPIOverride extends IWixAPI {
  window: IWixAPI['window'] & { postMessage: Worker['postMessage'] }
  location: IWixAPI['location'] & { to: (url: string) => void }
  seo: IWixAPI['seo'] & {
    renderSEOTags: (seo: DynamicItemPageSeo) => Promise<void>
  }
}

export class Platform implements PlatformInterface {
  constructor({
    platformUtils,
    platformSettings,
    wixSdk,
    bi,
    tpaActionImplementations,
    devMode,
    verbose,
  }: {
    platformUtils: PlatformUtilsOverride
    platformSettings: IAppData<AppData>
    wixSdk: IWixAPIOverride
    bi: IPlatformServices['bi']
    tpaActionImplementations: Record<Action, TpaActionImplementation>
    devMode: boolean
    verbose: boolean
  }) {
    this.#postMessage = (message: any) => wixSdk.window.postMessage(message)
    // you need to note, that some of those params are not static during app's lifecycle
    // some are static nowm but may change in the future.
    // for non-static props see the `#user` implementation
    this.#settings = this._getSettings({
      wixSdk,
      platformSettings,
      bi,
      devMode,
      verbose,
    })
    this.#user = this._getUser(wixSdk.user)
    this.#location = this._getLocation(wixSdk.location, bi)
    this.#utils = this._getUtils(platformUtils)
    this.#timers = this._getTimers(wixSdk)

    this.#executeTpaAction = async ({
      currentItem,
      action,
    }: {
      currentItem: RecordStoreRecord
      action: Action
    }) => {
      const implementation = tpaActionImplementations[action]
      await implementation({ wixSdk, currentItem })
    }
    this.#seo = this._getSeo(wixSdk)
  }

  get postMessage(): PlatformInterface['postMessage'] {
    return this.#postMessage
  }

  get settings(): PlatformInterface['settings'] {
    return this.#settings
  }

  get user(): PlatformInterface['user'] {
    return this.#user
  }

  get location(): PlatformInterface['location'] {
    return this.#location
  }

  get utils(): PlatformInterface['utils'] {
    return this.#utils
  }

  get timers(): PlatformInterface['timers'] {
    return this.#timers
  }

  get executeTpaAction(): PlatformInterface['executeTpaAction'] {
    return this.#executeTpaAction
  }

  get seo(): PlatformInterface['seo'] {
    return this.#seo
  }

  #settings
  #user
  #location
  #utils
  #timers
  #executeTpaAction
  #seo
  #postMessage

  _getSettings({
    wixSdk: {
      window: {
        viewMode,
        rendering: { env },
        browserLocale,
        multilingual: {
          currentLanguage,
          siteLanguages,
          isEnabled: multiLingualIsEnabled,
        },
      },
      site: { regionalSettings = browserLocale },
    },
    platformSettings: {
      url,
      instance,
      appData: {
        gridAppId,
        veloCodeIsPresentOnCurrentPage,
        blocksAppIsPresentOnSite,
      } = {
        gridAppId: '',
        veloCodeIsPresentOnCurrentPage: true,
        blocksAppIsPresentOnSite: false,
      },
    },
    bi: { metaSiteId, viewerName },
    devMode,
    verbose,
  }: {
    wixSdk: IWixAPI
    platformSettings: IAppData<AppData>
    bi: Bi
    devMode: boolean
    verbose: boolean
  }) {
    const locale = multiLingualIsEnabled
      ? siteLanguages.find(
        ({ languageCode }) => languageCode === currentLanguage,
      )?.locale ?? regionalSettings
      : regionalSettings

    return {
      metaSiteId,
      gridAppId,
      url,
      instance,
      locale,
      mode: {
        name: env,
        dev: devMode,
        verbose,
        ssr: env === 'backend',
        csr: env !== 'backend',
      },
      env: {
        name: viewMode,
        live: viewMode === 'Site',
        preview: viewMode === 'Preview',
        livePreview: viewMode === 'Editor',
        editor: viewMode === 'Preview' || viewMode === 'Editor',
        renderer: viewerName,
        veloCodeIsPresentOnCurrentPage,
        blocksAppIsPresentOnSite,
      },
    }
  }

  _getUser(user: IWixAPI['user']) {
    return {
      get id() {
        return user.currentUser.id
      },
      get loggedIn() {
        return user.currentUser.loggedIn
      },
      onLogin: user.onLogin,
    }
  }

  _getLocation(wixLocation: IWixAPIOverride['location'], { pageId }: Bi) {
    return {
      pageId,
      get pageUrl() {
        return wixLocation.url
      },
      get baseUrl() {
        return wixLocation.baseUrl
      },
      get path() {
        return wixLocation.path
      },
      queryParams: wixLocation.query,
      navigateTo: wixLocation.to,
      onChange: wixLocation.onChange,
    }
  }

  _getUtils({ links, mediaItemUtils }: PlatformUtilsOverride) {
    return {
      links,
      media: {
        ...mediaItemUtils,
        getScaleToFillImageURL: imageKitSDK.getScaleToFillImageURL,
      },
    }
  }

  _getTimers(wixSdk: IWixAPI) {
    return {
      queueMicrotask:
        wixSdk.environment?.timers?.queueMicrotask || queueMicrotask,
    }
  }

  _getSeo(wixSdk: IWixAPIOverride) {
    return {
      renderSEOTags: wixSdk.seo.renderSEOTags,
    }
  }
}

export type ControllerConfig = {
  compId: string
  type: typeof DATASET | typeof ROUTER_DATASET | typeof WIDGET_DATASET
  config: {
    dataset?: {
      collectionName: string | null
      filter: FilterTree | null
      sort: Sort | null
      includes: string[] | null
      nested: string[]
      pageSize: number
      readWriteType: 'READ' | 'WRITE' | 'READ_WRITE'
      deferred?: boolean
      cursor?: boolean
      private?: boolean
    }
  }
  connections: Connection[]
  essentials: { env: { compId: string } }
  livePreviewOptions?: {
    shouldFetchData?: boolean
    compsIdsToReset?: string[]
  }
}

export type RouterConfig = {
  config: {
    dataset: {
      collectionName: string
      filter: FilterTree | null
      sort: DatasetSort | null
      includes: string[] | null
      pageSize: number
      seoV2: boolean
    }
  }
  dynamicUrl: string
  userDefinedFilter: FilterTree
}

export const readWriteTypeMap = {
  READ: 'read' as const,
  WRITE: 'write' as const,
  READ_WRITE: 'read-write' as const,
}

const getUniqueFieldIdsFromConnections = (connections: Connection[]) => {
  const fieldIds = new Set<string>()
  for (const connection of connections) {
    const stack = [connection]

    while (stack.length) {
      const obj = stack.pop()
      if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
        continue
      }
      for (const [key, value] of Object.entries(obj)) {
        if (key === 'expressions') {
          return undefined
        }
        if (value && typeof value === 'string') {
          if (
            (key === 'action' && value.startsWith(tpaPrefix)) ||
            (key === 'role' && value === GRID_ROLE)
          ) {
            return undefined
          }
          if (key === 'fieldName') {
            fieldIds.add(value.split('.')[0])
            continue
          }
        }
        stack.push(value)
      }
    }
  }

  return fieldIds.size ? [...fieldIds] : ['_id']
}

const datasetTypeByControllerType = {
  [DATASET]: 'regular',
  [ROUTER_DATASET]: 'router',
  [WIDGET_DATASET]: 'widget',
} as const

export const controllerConfigToDatasetConverter =
  ({
    makeDataset,
    features,
  }: {
    makeDataset: typeof datasetEntity.make
    features: Features
  }) =>
    (controllerConfigs: ControllerConfig[], routerData?: RouterConfig) =>
      controllerConfigs.map(
        ({
          compId: originalCompId,
          type,
          connections,
          config: { dataset = {} },
          essentials: {
            env: { compId: scopedCompId },
          },
          livePreviewOptions: { shouldFetchData, compsIdsToReset } = {},
        }) => {
          const compId = type === WIDGET_DATASET ? scopedCompId : originalCompId
          const {
            readWriteType,
            deferred,
            cursor,
            nested,
            private: isPrivate,
          } = dataset
          const { collectionName, pageSize, filter, sort, includes } =
          type === ROUTER_DATASET ? routerData?.config.dataset ?? {} : dataset

          return makeDataset({
            config: {
              id: compId,
              type: datasetTypeByControllerType[type],
              collectionId: type === WIDGET_DATASET ? compId : collectionName,
              readWriteType: readWriteType
                ? readWriteTypeMap[readWriteType]
                : undefined,
              deferred,
              private: isPrivate,
              pagination: {
                pageSize,
                type: cursor ? 'cursor' : 'offset',
              },
              rendering: {
                connections,
                nestedFieldIds: nested,
                componentIdsToRender: compsIdsToReset,
              },
              dataLoading: {
                filter,
                sort,
                fieldIdsToFetch: features.fetchOnlyConnectedFields && readWriteType === 'READ'
                  ? getUniqueFieldIdsFromConnections(connections)
                  : undefined,
                referenceFieldIdsToFetch: includes ?? undefined,
                dataIsInvalidated: shouldFetchData,
              },
              routerConfig: routerData
                ? {
                  dynamicUrl: routerData.dynamicUrl,
                  userDefinedFilter: routerData.userDefinedFilter,
                  seoEnabled: routerData.config.dataset.seoV2,
                }
                : null,
            },
          })
        },
      )
