import { cloneDeep, noop } from 'lodash'
import createControllerStore from './controllerStore'
import initCreateDataset from './controller'
import {
  appContext,
  setAppContext,
} from '../viewer-app-module/DataBindingAppContext'
import datasetEntity from '../dataset/dataset-entity'

const controllerFactory = (logger, datasetParams) => {
  const controllerStore = createControllerStore(logger)
  const factory = {
    createRealDataset: () =>
      createDataset({ ...datasetParams, isScoped: false }),

    createScopedDetailsDataset: ({ datasetScope }) => {
      const parentDataset = appContext.datasetStore[datasetParams.datasetId]
      const virtualDataset = datasetEntity.makeVirtual(parentDataset, {
        scopeId: datasetScope.repeaterId,
        itemId: datasetScope.itemId,
      })
      setAppContext({
        datasetStore: {
          ...appContext.datasetStore,
          [virtualDataset.config.id]: virtualDataset,
        },
      })
      return createDataset({
        ...datasetParams,
        isScoped: true,
        firePlatformEvent: noop,
        datasetId: virtualDataset.config.id,
        datasetScope,
        // new contract
        dataset: virtualDataset,
      })
    },

    createScopedDataset: ({ datasetScope, fixedItem }) => {
      const { dataProvider, controllerConfig, dynamicPagesData } = datasetParams
      const newControllerConfig = cloneDeep(controllerConfig)
      const fixedData = { items: [fixedItem] }
      newControllerConfig.dataset.filter = dataProvider.createSimpleFilter(
        '_id',
        fixedItem._id,
      )

      // TODO: case for repeater whose data is set via userCode. We should everything only via datasetAPI
      const { collectionName: collectionId } = controllerConfig.dataset
      dataProvider.setCollectionData({ collectionId, data: fixedData })

      const parentDataset = appContext.datasetStore[datasetParams.datasetId]
      const virtualDataset = datasetEntity.makeVirtual(parentDataset, {
        scopeId: datasetScope.repeaterId,
        itemId: fixedItem._id,
        scopedToItem: true,
      })
      setAppContext({
        datasetStore: {
          ...appContext.datasetStore,
          [virtualDataset.config.id]: virtualDataset,
        },
      })

      return createDataset({
        ...datasetParams,
        isScoped: true,
        controllerConfig: newControllerConfig,
        firePlatformEvent: noop,
        dynamicPagesData,
        datasetId: virtualDataset.config.id,
        fixedRecordId: datasetScope.itemId,
        // new contract
        dataset: virtualDataset,
      })
    },
  }
  const createDataset = initCreateDataset(factory, controllerStore)

  return factory
}

export default controllerFactory
