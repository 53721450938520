import { forEach, pickBy } from 'lodash'
import {
  generateAutomationsFieldName,
  isFieldSupported,
} from '@wix/wix-code-automations-client'
import convertValueToString from '../../components/transformData/convertValueToString' //TODO: consider using transformFromRecordToView
import { getFieldValue } from '../../components/helpers'
import type { RecordStoreRecord } from '../../record-store/service'
import {
  FieldType,
  type FieldSpec,
  type ReferenceField,
  type Schema,
} from '@wix/wix-data-schema-types'

export type FieldValue = string | boolean

export type DetailedEventPayload = {
  [key: string]: {
    value: FieldValue
    keyName: string
    index?: number
    valueType?: FieldType
  }
}

const formatFieldValue = <T>(value: T, type: FieldType) =>
  type === FieldType.boolean ? value : convertValueToString(value)

const createDetailedEventPayload = ({
  datasetId,
  record,
  schema,
  getSchema,
}: {
  datasetId: string
  record: RecordStoreRecord
  schema: Schema
  getSchema: (collection?: string) => Schema | null
}): DetailedEventPayload => {
  const payload: DetailedEventPayload = {
    'form-id': {
      value: datasetId,
      keyName: '',
    },
  }

  const supportedNotDeletedSchemaFields = pickBy(
    schema.fields,
    (fieldData, fieldName) =>
      isFieldSupported(fieldData, fieldName) && !isFieldDeleted(fieldData),
  )

  forEach(supportedNotDeletedSchemaFields, (fieldData, fieldName) => {
    const valueDescriptor = getFieldValueAndType({
      record,
      fieldData,
      fieldName,
      getSchema,
    })

    if (!valueDescriptor) {
      return
    }

    const { value, type } = valueDescriptor
    const { displayName: keyName, index } = fieldData
    const automationsFieldName = generateAutomationsFieldName(
      datasetId,
      fieldName,
    )

    payload[`field:${automationsFieldName}`] = {
      value,
      keyName,
      index,
      valueType: type,
    }
  })

  return payload
}

export const createFormEventPayload = ({
  datasetId,
  getSchema,
  record,
  schema,
}: {
  datasetId: string
  getSchema: (collection?: string) => Schema | null
  record: RecordStoreRecord
  schema: Schema
}) => ({
  eventUTCTime: getFieldValue(record, '_updatedDate'),
  detailedEventPayload: createDetailedEventPayload({
    datasetId,
    record,
    schema,
    getSchema,
  }),
  collectionId: schema.id,
  submitterId: record._owner,
})

function isFieldDeleted(fieldData: FieldSpec) {
  return !!fieldData.isDeleted
}

function getFieldValueAndType({
  record,
  fieldData,
  fieldName,
  getSchema,
}: {
  record: RecordStoreRecord
  fieldData: FieldSpec
  fieldName: string
  getSchema: (
    collection?: ReferenceField['referencedCollection'],
  ) => Schema | null
}) {
  if (fieldData.type === FieldType.reference) {
    const schema = getSchema(fieldData.referencedCollection)
    if (!schema || !schema.displayField) {
      return undefined
    }
    const fieldValue = getFieldValue(record[fieldName], schema.displayField)
    return {
      value: formatFieldValue(fieldValue, fieldData.type),
      type: schema.fields[schema.displayField].type,
    }
  }

  const fieldValue = getFieldValue(record, fieldName)

  return {
    value: formatFieldValue(fieldValue, fieldData.type),
    type: fieldData.type,
  }
}
