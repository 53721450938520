export const omitNotRelevantControllersForLivePreview = (
  createControllers,
  rawControllerConfigs,
) => {
  const skippedIndexes = []
  const skipNotRelevantForLivePreviewControllers = (
    controllerConfig,
    index,
  ) => {
    if (controllerConfig.type === 'widget') {
      skippedIndexes.push(index)
      return false
    }
    return true
  }
  const controllers = createControllers(
    rawControllerConfigs.filter(skipNotRelevantForLivePreviewControllers),
  )

  if (skippedIndexes.length === 0) {
    return controllers
  }

  const controllersInInitialOrder = new Array(rawControllerConfigs.length)
    .fill(undefined)
    .map((_, index) => {
      if (skippedIndexes.length && skippedIndexes[0] === index) {
        skippedIndexes.shift()
        return undefined
      }
      return controllers.shift()
    })

  return controllersInInitialOrder
}
