import { implementations as _tpaActionImplementations } from '@wix/data-binding-tpa-actions'
import '../helpers/polyfills'
import parseUrl from 'url-parse'
import { viewerAutomationsClientCreator } from '@wix/wix-code-automations-client'
import { Trace, Breadcrumb, AppError } from '../logger'
import FesDataFetcher from '../inverted-dependencies/FesDataFetcher'
import WixDataFetcher from '../inverted-dependencies/WixDataFetcher'
import { WarmupCache } from '../inverted-dependencies/WarmupCache'
import StaticCache from '../inverted-dependencies/StaticCache'
import { createFeatures } from '../inverted-dependencies/Features'
import Logger from '../inverted-dependencies/Logger'
import DataBinding from './DataBinding'
import { createListenersByEvent } from '../inverted-dependencies/createListenersByEvent'
import { createDataSchemasClientForBrowser } from '@wix/wix-data-schemas-client'
import {
  Platform,
  controllerConfigToDatasetConverter,
} from '../inverted-dependencies/Platform'
import { createComponentFactory } from '../inverted-dependencies/components'
import datasetEntity from '../dataset/dataset-entity'
import i18nCreatorLite from '../helpers/i18nCreatorLite'
import { loadExpressionFunctions } from '@wix/expressions'
import { createAutomationsV2Client } from '../inverted-dependencies/automationsV2Client'
import { SCOPE_TYPES } from '@wix/wix-data-client-dbsm-common/src/scopes/consts'
import { WIDGET_RECORD_ID } from '../helpers/constants'
import { assertValidDataSource } from '../dataset-api/datasetApiAssertions'
import { omitNotRelevantControllersForLivePreview } from '../helpers/omitNotRelevantControllersForLivePreview'

const setWebpackPublicPathFromAppUrl = url => {
  try {
    __webpack_public_path__ = url.substr(0, url.lastIndexOf('/') + 1)// eslint-disable-line
  } catch {}
}

export const createApp = ({
  verbose = false,
  //TODO: This stuff is in constructor, because it can be passed from IT tests.
  //TODO: Should be removed after it tests for internal business logic migration to units.
  //TODO: And WixDataFetcher integration with wix data and schemas should be tested separately
  wixDataSchemasForItTests,
  automationsClientCreator = viewerAutomationsClientCreator,
  tpaActionImplementations = _tpaActionImplementations,
} = {}) => {
  let routerData
  let dataBinding
  let features
  let platform
  const initAppForPage = (
    platformSettings,
    platformUtils,
    wixSdk,
    {
      bi = {},
      monitoring: { createMonitor },
      fedOpsLoggerFactory,
      biLoggerFactory,
      essentials: { httpClient, experiments, createErrorMonitor },
    } = {},
  ) => {
    let logger
    try {
      platform = new Platform({
        platformUtils,
        platformSettings,
        wixSdk,
        bi,
        tpaActionImplementations,
        devMode: false,
        verbose,
      })
      const { settings } = platform
      setWebpackPublicPathFromAppUrl(platform.settings.url)

      const {
        data: wixData,
        window: { warmupData, getRouterData },
        location: { baseUrl, protocol },
        site: { language, currentPage },
      } = wixSdk

      routerData = getRouterData()

      logger = new Logger({
        fedops: {
          factory: fedOpsLoggerFactory,
          hooks: {
            start: ({ name }) =>
              logger.log(
                new Breadcrumb({
                  category: 'interaction start',
                  message: `interaction ${name} started`,
                }),
              ),
            end: ({ name, duration }) =>
              logger.log(
                new Breadcrumb({
                  category: 'interaction end',
                  message: `interaction ${name} ended after ${duration} ms`,
                }),
              ),
          },
        },
        bi: { factory: biLoggerFactory },
        monitor: { factory: createMonitor, createErrorMonitor },
        verbose: {
          factory: () => ({
            // eslint-disable-next-line no-console
            log: (...args) =>
              (wixSdk.telemetry?.console || console).verbose(...args),
          }),
        },
        console: {
          factory: () => wixSdk.telemetry?.console || console,
        },
        platform,
        global: self,
      })

      logger.log(new Trace('databinding/initAppForPage', Trace.types.START))

      const i18n = i18nCreatorLite(language)

      features = createFeatures({ experiments, settings })

      const { instance, gridAppId } = platform.settings
      const dataFetcher = features.fes
        ? new FesDataFetcher({
            httpClient,
            getRequestParams: () => ({ instance, gridAppId }),
          })
        : new WixDataFetcher({
            wixData,
            wixDataSchemas:
              wixDataSchemasForItTests ||
              createDataSchemasClientForBrowser(
                httpClient,
                instance,
                gridAppId,
                {
                  baseUrl: getBaseUrl({
                    envIsEditor: settings.env.editor,
                    baseUrl,
                    protocol,
                    shouldUseCloudDataUrlWithBaseExternalUrl:
                      features.useCloudDataUrlWithBaseExternalUrl,
                  }),
                  useApiV2: true,
                },
              ),
          })

      const warmupCache = new WarmupCache(warmupData)
      const staticCache = new StaticCache(routerData)
      const listenersByEvent = createListenersByEvent({
        automationsClientCreator: () =>
          features.automationsClientV2
            ? createAutomationsV2Client(httpClient)
            : automationsClientCreator({
                httpClient,
              }),
        pageId: currentPage.id,
      })

      dataBinding = new DataBinding({
        platform,
        dataFetcher,
        warmupCache,
        staticCache,
        features,
        listenersByEvent,
        logger,
        i18n,
        global: self,
        loadExpressionFunctions,
      })

      logger.log(new Trace('databinding/initAppForPage', Trace.types.END))

      return Promise.resolve()
    } catch (e) {
      if (logger) {
        logger.log(new AppError('App initialisation failed', { cause: e }))
      }
      return Promise.reject(e)
    }
  }

  const createControllers = rawControllerConfigs => {
    if (!rawControllerConfigs.length) {
      return []
    }

    const convertControllerConfigToDataset = controllerConfigToDatasetConverter(
      {
        makeDataset: datasetEntity.make,
        features,
      },
    )
    const datasetConfigs = convertControllerConfigToDataset(
      rawControllerConfigs,
      routerData,
    )

    const fireEventByDatasetId = rawControllerConfigs.reduce(
      (acc, { $w, compId }) => {
        acc[compId] = $w.fireEvent
        return acc
      },
      {},
    )

    return dataBinding
      .initializeDatasets({
        datasetConfigs,
        firePlatformEvent: datasetId => fireEventByDatasetId[datasetId],
      })
      .map((dataset, index) => {
        if (datasetConfigs[index].config.type === 'widget') {
          dataset.staticExports.initWidgetDataSource = ({
            getData,
            onDataChanged,
          }) => {
            assertValidDataSource('initWidgetDataSource', {
              getData,
              onDataChanged,
            })
            const getRecordFromWidgetData = widgetData => ({
              ...widgetData,
              _id: WIDGET_RECORD_ID,
            })
            return dataset.api.initDataSource({
              getData: () => [getRecordFromWidgetData(getData())],
              onDataChanged: cb =>
                onDataChanged((oldData, newData) =>
                  cb(
                    [getRecordFromWidgetData(oldData)],
                    [getRecordFromWidgetData(newData)],
                  ),
                ),
              getSchema: () => ({ fields: {}, displayName: '', id: 'WIDGET' }),
            })
          }
        }

        const exports = (scope /*, $w*/) => {
          switch (scope.type) {
            case SCOPE_TYPES.COMPONENT:
              return dataset.staticExports.inScope(
                scope.compId,
                scope.additionalData.itemId,
              )
            default:
              return dataset.staticExports
          }
        }

        const pageReady = $w => dataset.pageReady(createComponentFactory($w))

        return {
          pageReady,
          exports,
          dispose: dataset.dispose,
        }
      })
  }

  const getBaseUrl = ({
    envIsEditor,
    baseUrl,
    protocol,
    shouldUseCloudDataUrlWithBaseExternalUrl,
  }) => {
    const cloudDataEndpointSuffix = `_api/cloud-data`
    if (envIsEditor) {
      return `/${cloudDataEndpointSuffix}`
    }
    if (shouldUseCloudDataUrlWithBaseExternalUrl) {
      return `${baseUrl}/${cloudDataEndpointSuffix}`
    }
    return `${protocol}://${parseUrl(baseUrl).hostname}/${cloudDataEndpointSuffix}`
  }

  return {
    initAppForPage,
    createControllers: rawControllerConfigs => {
      if (
        platform?.settings.env.livePreview &&
        platform?.location.baseUrl.startsWith('https://blocks.wix.com') // TODO: change when solution from the platform exists https://wix.slack.com/archives/C07LGCVH5EH/p1736860995482919
      ) {
        return omitNotRelevantControllersForLivePreview(
          createControllers,
          rawControllerConfigs,
        )
      }
      return createControllers(rawControllerConfigs)
    },
  }
}
