import type { Dataset, LocationQueryParams } from '../contract'
import type { DeepPartial } from '../utils/type-utils'
import { defaultDatasetConfiguration as commonDefaultDatasetConfig } from '@wix/wix-data-client-common'
import { readWriteTypeMap } from '../inverted-dependencies/Platform'
import { cleanObject } from '../utils'

export const defaultRegularDatasetConfig = {
  id: '',
  parentId: null,
  type: 'regular' as const,
  collectionId: commonDefaultDatasetConfig.collectionName,
  readWriteType: readWriteTypeMap[commonDefaultDatasetConfig.readWriteType],
  deferred: commonDefaultDatasetConfig.deferred,
  private: false,
  pagination: {
    pageSize: commonDefaultDatasetConfig.pageSize,
    currentPage: 1,
    type: 'offset' as const,
  },
  rendering: {
    nestedFieldIds: [],
    connections: [],
    componentIdsToRender: [],
  },
  dataLoading: {
    dataIsInvalidated: false,
    referenceFieldIdsToFetch: [],
    filter: commonDefaultDatasetConfig.filter,
    sort: commonDefaultDatasetConfig.sort,
  },
  routerConfig: null,
}

const defaultRouterDatasetConfig = {
  ...defaultRegularDatasetConfig,
  type: 'router' as const,
}

const defaultWidgetDatasetConfig = {
  ...defaultRegularDatasetConfig,
  type: 'widget' as const,
  collectionId: 'WIDGET',
}

const make = ({ config, data = null }: DeepPartial<Dataset> = {}): Dataset => {
  let defaultDatasetConfig: Dataset['config']
  switch (config?.type) {
    case 'router':
      defaultDatasetConfig = defaultRouterDatasetConfig
      break
    case 'widget':
      defaultDatasetConfig = defaultWidgetDatasetConfig
      break
    default:
      defaultDatasetConfig = defaultRegularDatasetConfig
  }
  return {
    config: {
      ...defaultDatasetConfig,
      ...cleanObject(config),
      pagination: {
        ...defaultDatasetConfig.pagination,
        ...cleanObject(config?.pagination),
      },
      rendering: {
        ...defaultDatasetConfig.rendering,
        ...cleanObject(config?.rendering),
      },
      dataLoading: {
        ...defaultDatasetConfig.dataLoading,
        ...cleanObject(config?.dataLoading),
      },
    },
    data,
  }
}

const makeVirtual = (
  dataset: Dataset,
  {
    scopeId,
    itemId,
    scopedToItem = false,
  }: { scopeId: string; itemId: string; scopedToItem?: boolean },
) => ({
  ...dataset,
  config: {
    ...dataset.config,
    id: `${dataset.config.id}_componentId_${scopeId}_itemId_${itemId}`,
    parentId: dataset.config.id,
    pagination: {
      ...dataset.config.pagination,
      currentPage: 1,
      pageSize: scopedToItem ? 1 : dataset.config.pagination.pageSize,
    },
    dataLoading: {
      ...dataset.config.dataLoading,
      filter: scopedToItem
        ? { _id: { $eq: itemId } }
        : dataset.config.dataLoading.filter,
    },
  },
})

const getId = ({ config: { id } }: Dataset) => id

const isReal = (dataset: Dataset) => dataset.config.parentId === null

const isWriteOnly = (dataset: Dataset) =>
  dataset.config.readWriteType === 'write'

const isDeferred = (dataset: Dataset) => dataset.config.deferred

const isPrivate = (dataset: Dataset) => dataset.config.private

const isWidget = (dataset: Dataset) => dataset.config.type === 'widget'

const getCurrentPage = ({
  config: {
    pagination: { currentPage },
  },
}: Dataset) => currentPage // current page numeration starts from 1

const getPageSize = ({
  config: {
    pagination: { pageSize },
  },
}: Dataset) => pageSize

const getOffset = (dataset: Dataset) => {
  return (getCurrentPage(dataset) - 1) * getPageSize(dataset)
}

const getCollectionId = ({ config: { collectionId } }: Dataset) => collectionId

const getFieldIdsToFetch = (dataset: Dataset) =>
  dataset.config.dataLoading.fieldIdsToFetch

const getPaginationType = (dataset: Dataset) => dataset.config.pagination.type

const isPaginationCursor = (dataset: Dataset) =>
  getPaginationType(dataset) === 'cursor'

const initCurrentPage = (
  dataset: Dataset,
  queryParams: LocationQueryParams,
) => {
  let currentPage: number
  if (isItemPageRouter(dataset)) {
    currentPage = 1
  } else {
    currentPage = Number(
      queryParams[
        isRouter(dataset) ? 'dynamic_page' : `${dataset.config.id}_page`
      ] ?? 1,
    )
  }
  return {
    ...dataset,
    config: {
      ...dataset.config,
      pagination: {
        ...dataset.config.pagination,
        currentPage,
      },
    },
  }
}

const isRouter = (dataset: Dataset) => dataset.config.type === 'router'

const isItemPageRouter = (dataset: Dataset) =>
  isRouter(dataset) &&
  Boolean(dataset.config.routerConfig?.dynamicUrl?.match(/\{.+\}/)) &&
  //TODO: Check a possibility to remove protective coding, dynamicUrl should always be a string by our contract. If so, fix in site creators
  dataset.config.pagination.pageSize === 1

export default {
  make,
  makeVirtual,
  getId,
  isReal,
  isRouter,
  isItemPageRouter,
  isWriteOnly,
  isDeferred,
  isWidget,
  getCurrentPage,
  getPageSize,
  getOffset,
  getCollectionId,
  getFieldIdsToFetch,
  initCurrentPage,
  getPaginationType,
  isPaginationCursor,
  isPrivate,
}
