import * as ar from './keys/messages_ar.json'
import * as bg from './keys/messages_bg.json'
import * as ca from './keys/messages_ca.json'
import * as cs from './keys/messages_cs.json'
import * as da from './keys/messages_da.json'
import * as de from './keys/messages_de.json'
import * as el from './keys/messages_el.json'
import * as en from './keys/messages_en.json'
import * as es from './keys/messages_es.json'
import * as fr from './keys/messages_fr.json'
import * as he from './keys/messages_he.json'
import * as hi from './keys/messages_hi.json'
import * as hu from './keys/messages_hu.json'
import * as id from './keys/messages_id.json'
import * as it from './keys/messages_it.json'
import * as ja from './keys/messages_ja.json'
import * as ko from './keys/messages_ko.json'
import * as lt from './keys/messages_lt.json'
import * as ms from './keys/messages_ms.json'
import * as nl from './keys/messages_nl.json'
import * as no from './keys/messages_no.json'
import * as pl from './keys/messages_pl.json'
import * as pt from './keys/messages_pt.json'
import * as ro from './keys/messages_ro.json'
import * as ru from './keys/messages_ru.json'
import * as sk from './keys/messages_sk.json'
import * as sl from './keys/messages_sl.json'
import * as sv from './keys/messages_sv.json'
import * as th from './keys/messages_th.json'
import * as tl from './keys/messages_tl.json'
import * as tr from './keys/messages_tr.json'
import * as uk from './keys/messages_uk.json'
import * as vi from './keys/messages_vi.json'
import * as zh from './keys/messages_zh.json'

export type Language = string
export type Translations = typeof en
export type TranslationKeys = keyof Translations

export const translationsByLanguage: Record<Language, Translations> = {
  ar,
  bg,
  ca,
  cs,
  da,
  de,
  el,
  en,
  es,
  fr,
  he,
  hi,
  hu,
  id,
  it,
  ja,
  ko,
  lt,
  ms,
  nl,
  no,
  pl,
  pt,
  ro,
  ru,
  sk,
  sl,
  sv,
  th,
  tl,
  tr,
  uk,
  vi,
  zh,
}
