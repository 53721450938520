import seedDataFetcher from './seed'
import { Deferred } from '../helpers'
import { hasUserInputDependencies } from '../filter-resolvers'
import { appContext } from '../viewer-app-module/DataBindingAppContext'
import { getUserFilterConnectionProps } from './rootReducer'
import { setUserFilterInitialData } from '../records/actions'
import { getSort } from '@wix/wix-data-client-common-standalone'
import datasetEntity from '../dataset/dataset-entity'
import { datasetIsControlledByDataSource } from '../helpers/datasetUtils'
import { DataBindingBi } from '../logger'

const getDeferredDependency = (modeIsSSR, queueMicrotask) => {
  const { promise: deferringDataFetch, resolve: resolveDeferredDataFetcher } =
    new Deferred()
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_DOM_API/Microtask_guide
  // this way we gaurantee deferred controller(dataset) data fetch request
  // to be executed after createControllers returns controllers to the platform
  if (!modeIsSSR) {
    queueMicrotask(resolveDeferredDataFetcher)
  }
  return deferringDataFetch
}
const getUserInputDependency = () => {
  const { promise: waitingForUserInput, resolve: resolveUserInputDependency } =
    new Deferred()
  return { waitingForUserInput, resolveUserInputDependency }
}

const getDataSourceDependency = () => {
  const {
    promise: waitingForDataSource,
    resolve: resolveDataSourceDependency,
  } = new Deferred()
  return { waitingForDataSource, resolveDataSourceDependency }
}

const fetchData = ({
  shouldFetchInitialData,
  recordStore,
  store,
  filter,
  sort,
  datasetIsDeferred,
  modeIsSSR,
  queueMicrotask,
  datasetIsReal,
  collectionId,
  filterResolver,
  dependencyManager,
  getSchemas,
  schemasLoading,
  getUserFilterInitialData,
  datasetId,
}) => {
  const fetchInitialData = () => {
    const userFilterConnectionProps = getUserFilterConnectionProps(
      store.getState(),
    )
    const offset = datasetEntity.getOffset(appContext.datasetStore[datasetId])
    const seedDataResult = shouldFetchInitialData
      ? seedDataFetcher({ recordStore, offset })
      : Promise.resolve()

    const shouldFetchUserFilterConnectionProps =
      datasetIsReal && userFilterConnectionProps?.length > 0
    if (!shouldFetchUserFilterConnectionProps) {
      // Make promise-like type into promise
      return seedDataResult.then(seedData => Promise.resolve([seedData]))
    }

    const userFilterInitialDataResult = filterResolver(filter)
      .map(async resolvedFilter => {
        const availableUserFilterData = getUserFilterInitialData()
        if (availableUserFilterData) {
          store.dispatch(setUserFilterInitialData(availableUserFilterData))
          return availableUserFilterData
        }

        await schemasLoading
        const schemas = getSchemas()
        const schema = schemas[collectionId]

        if (schema) {
          const resolvedSort = await getSort({
            datasetConfigSort: sort,
            getSchema: () => schema,
          })
          // TODO: Revert me after test https://wix.slack.com/archives/CGW2XUZ41/p1735913504205999
          if (
            appContext.platform.settings.env.live &&
            (!sort || sort.length === 0) &&
            resolvedSort
          ) {
            self.setTimeout(() =>
              appContext.logger.log(
                new DataBindingBi({ id: 901, collectionId }),
              ),
            )
          }
          const userFilterInitialData =
            await appContext.dataFetcher.fetchUserFilterInitialData({
              filter: resolvedFilter,
              sort: resolvedSort,
              userFilterConnectionProps,
              schema,
              schemas,
            })

          store.dispatch(setUserFilterInitialData(userFilterInitialData))
          return userFilterInitialData
        }
      })
      .getOrElse(null)

    return Promise.all([seedDataResult, userFilterInitialDataResult])
  }

  const dependencyResolutionPromise =
    dependencyManager.getDependencyResolutionPromise(filter)

  const deferringDataFetch =
    datasetIsDeferred && getDeferredDependency(modeIsSSR, queueMicrotask)

  const { waitingForUserInput, resolveUserInputDependency } =
    hasUserInputDependencies(filter) && getUserInputDependency()

  const { waitingForDataSource, resolveDataSourceDependency } =
    datasetIsControlledByDataSource(appContext.datasetStore[datasetId]) &&
    getDataSourceDependency()

  const fetchDataDependencies = [
    dependencyResolutionPromise,
    deferringDataFetch,
    waitingForUserInput,
    waitingForDataSource,
  ].filter(item => Boolean(item))

  const fetchingInitialData = fetchDataDependencies.length
    ? Promise.all(fetchDataDependencies).then(fetchInitialData)
    : fetchInitialData()

  return {
    fetchingInitialData,
    // TODO: Next 2 methods should be combined in a sync single one immediately after fetchingControllerDeps refactoring
    // because we don't need async in a virtual controller
    resolveUserInputDependency: () =>
      resolveUserInputDependency && resolveUserInputDependency(),
    resolveDataSourceDependency: () => {
      if (resolveDataSourceDependency) {
        resolveDataSourceDependency()
      }
    },
  }
}

export default fetchData
